.btn-add {
    width: 12%;
    border: 1px solid !important;
    display: flex !important;
    justify-content: center;
}
.icon-add-student {
    margin-bottom: 3px;
    margin-right: 7px;
}
.div-btn {
    display: flex !important;
    justify-content: right;
}
.error-message {
    color: red;
}
.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}
input:read-only {
    background-color: #E7E9EB;
}

.background2e3650 {
    background-color: #2e3650 !important;
    border-color: #2e3650 !important;
    color: white !important;
}