input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 0;
    display: none;
}
.is-invalid {
    border: 1px solid red !important;
}

.th-unit-name {
    height: 126px;
    width: 250px;
    overflow-y: auto;
    padding-left: 10px;
}
.th-unit-name-course {
    height: 126px;
    width: 350px;
    overflow-y: auto;
    padding-left: 10px;
}

.unit-name-list {
    max-width: 300px;
    word-break: break-word;
}

.subskill-name-div {
    margin-left: -7px;
}

.th-detail {
    font-size: 0.9rem !important;
    text-align: center;
}

.text-note-contain {
    color: #006fffe6 !important;
}

.td-week-detail {
    text-align: right;
    font-size: 0.9rem !important;
    width: 90%;
    margin-top: 0px;
}

.modal-body {
    max-height:700px; 
    overflow-y: auto;
}
